<template>
  <b-modal
    size="lg"
    title-class="font-18"
    hide-footer
    v-model="open"
    @hidden="$emit('hideModal')"
  >
    <FormulateForm v-model="values" name="vendor" @submit="submitHandler">
      <FormulateInput
        name="full_name"
        type="text"
        label="Fulll Name"
        placeholder="Full name here"
        validation="required"
        :validationMessages="{ required: 'Full name required.' }"
      />
      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="license_no"
            type="text"
            label="License No."
            placeholder="License No. here"
            validation="required"
            :validationMessages="{ required: 'License number is required.' }"
          />
        </div>
        <div class="col-md-6">
          <div v-if="!showTribe">
            <FormulateInput
              aria-hidden="true"
              name="assigned_tribe"
              :options="tribeAvailable"
              :help="
                loadingTribes
                  ? 'Tribe list is loading,Please wait'
                  : tribeAvailable.length
                  ? ''
                  : 'No tribes available'
              "
              placeholder="Select an option ⬇"
              label="Tribe"
              type="select"
            />
          </div>
          <div v-else>
            <div @click="showTribeDropdown()">
              <FormulateInput
                :disabled="true"
                name="selectedTribeName"
                type="text"
                label="Tribe Name"
                placeholder="Tribe name. here"
                :validationMessages="{
                  required: 'License number is required.',
                }"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="email"
            type="email"
            label="Email address"
            placeholder="Email address"
            validation="required|email"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="contact_number"
            type="tel"
            label="Contact"
            placeholder="Phone number here!"
            validation="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <FormulateInput
            name="location"
            type="text"
            label="Location."
            placeholder="Location here"
            validation="required"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            name="password"
            type="toggable-password"
            label="Password"
            placeholder="Your password"
            :validation="vendorSelected ? null : 'required'"
          />
        </div>
      </div>
      <div class="right-align mt-4 mb-2">
        <FormulateInput
          type="submit"
          :label="vendorSelected ? 'Update Vendor' : 'Add Vendor'"
        />
      </div>
    </FormulateForm>
  </b-modal>
</template>
<script>
import {
  createVendor,
  loadAvailableTribes,
  updateVendor,
} from "@/services/api/user";
export default {
  data() {
    return {
      open: this.openModal,
      values: {},
      tribeAvailable: [],
      showTribe: true,
      loadingTribes: true,
    };
  },
  created() {
    this.loadAvailableTribes();
  },
  mounted() {
    this.showTribe = true;
    this.$formulate.reset("vendor");
  },

  props: ["openModal", "vendorSelected"],
  watch: {
    openModal(val) {
      this.open = val;
    },
    vendorSelected(val) {
      console.log(val);
      this.values = val;
    },
  },
  methods: {
    showTribeDropdown() {
      this.showTribe = !this.showTribe;
    },
    submitHandler(data) {
      if (this.vendorSelected) {
        data.vendorId = data._id;
        updateVendor(data)
          .then((res) => {
            if (res.data.vendor) {
              this.$bvToast.toast("Updated successfully!", {
                title: "Vendor",
                variant: "success",
                solid: true,
              });
              this.$emit("dataAdded");
              this.$formulate.reset("vendor");
              this.$emit("hideModal");
            } else {
              this.$bvToast.toast(res.data.message, {
                title: "Vendor",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            console.log(err.message);
            this.$bvToast.toast(err.message, {
              title: "Vendor",
              variant: "danger",
              solid: true,
            });
          });
      } else {
        createVendor(data)
          .then((res) => {
            console.log("add", res.data);
            if (res.data.vendor) {
              this.$bvToast.toast("Added successfully!", {
                title: "Vendor",
                variant: "success",
                solid: true,
              });
              this.$emit("dataAdded");
              this.$formulate.reset("vendor");
            } else {
              this.$bvToast.toast(res.data.message, {
                title: "Vendor",
                variant: "danger",
                solid: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            this.$bvToast.toast(err.message, {
              title: "Vendor",
              variant: "danger",
              solid: true,
            });
          });
      }
    },
    loadAvailableTribes() {
      this.tribeAvailable = [];
      this.loadingTribes = true;
      loadAvailableTribes()
        .then((res) => {
          res.data.data.forEach((ele) => {
            this.tribeAvailable.push({
              label: ele.name,
              value: ele._id,
            });
          });
          console.log(this.tribeAvailable);
        })
        .catch((err) => {
          console.log(err.response);
        })
        .finally(() => {
          this.loadingTribes = false;
        });
    },
  },
};
</script>
<style>
</style>
