<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import vendorModal from "@/components/vendor-modal.vue";
import {
  getAllVendor,
  deleteVendor,
  removeTribeFromVendor,
} from "@/services/api/user";
/**
 * Advanced table component
 */
export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, vendorModal },
  data() {
    return {
      openModal: false,
      tableData: [],
      vendorSelected: null,
      title: "Advanced Table",
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "full_name" },
        { key: "contact" },
        { key: "tribe" },
        { key: "location" },
        { key: "createdAt" },
        { key: "action" },
      ],
      dataLoading: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created() {
    this.getAllVendor();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    AddVendor() {
      this.openModal = true;
    },
    getAllVendor() {
      this.tableData = [];
      this.dataLoading = true;
      getAllVendor({ page: 1, perPage: 10 })
        .then((res) => {
          console.log(res.data);
          this.tableData = res.data.vendors;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log("Loaded");
        });
    },
    deleteVendor(id) {
      this.$bvModal
        .msgBoxConfirm("Do you want to delete?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            deleteVendor(id)
              .then(() => {
                this.$bvToast.toast("Vendor removed successfully!", {
                  title: "Vendor",
                  variant: "success",
                  solid: true,
                });
                this.tableData = this.tableData.filter(
                  (item) => item._id !== id
                );
              })
              .catch(() => {
                this.$bvToast.toast("Vendor deletion failed!", {
                  title: "Vendor deletion",
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
      console.log(id);
    },
    editVendor(data) {
      this.vendorSelected = data.item;
      this.openModal = true;

      this.vendorSelected.selectedTribeName = data.item.assigned_tribe.name;
      this.vendorSelected.password = null;
    },

    removeTribeFromVendor(id) {
      removeTribeFromVendor(id)
        .then((res) => {
          this.$bvToast.toast("Vendor removed successfully!", {
            title: "Vendor",
            variant: "success",
            solid: true,
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    modalClosed() {
      this.openModal = false;
      this.vendorSelected = null;
    },
    dataAdded() {
      this.getAllVendor();
    },
  },
};
</script>

<template>
  <Layout>
    <div class="right-align m-2">
      <b-button variant="primary" @click="AddVendor">
        <i class="fas fa-user-plus"></i><span class="pl-2">Vendor</span>
      </b-button>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(action)="data">
                  <a
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="editVendor(data)"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    title="Delete"
                    @click="deleteVendor(data.item._id)"
                  >
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </template>
                <template #cell(contact)="data">
                  {{ data.item.email }} <br />
                  <strong>Phone: </strong> {{ data.item.contact_number }}
                </template>
                <template #cell(createdAt)="data">
                  {{ moment(data.item.createdAt).format("LLL") }}
                </template>
                <template #cell(tribe)="data">
                  <span v-if="data.item.assigned_tribe"
                    >{{ data.item.assigned_tribe.name }}<br />
                    <button
                      class="tribe-remove-btn"
                      @click="removeTribeFromVendor(data.item._id)"
                    >
                      Remove
                    </button>
                  </span>
                  <span v-else>No tribe assigned </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vendorModal
      :openModal="openModal"
      v-on:hideModal="modalClosed"
      :vendorSelected="vendorSelected"
      v-on:dataAdded="dataAdded"
    />
  </Layout>
</template>

<style scoped>
.tribe-remove-btn {
  border: none;
  background-color: antiquewhite;
  box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  font-size: 12px;
}
</style>